<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.custom-file-label {
  padding-top: 4px;
}
/deep/ .el-upload--text {
  width: 100% !important;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import PersonHeader from "@/views/pages/admin/user/examinee/person-header.vue";
import {
  saveStandardPhoto,
  getStandardPhoto,
  saveIdentificationPhoto,
} from "@/api/userInfo_user.js";
import YzModal from "@/components/form/yzModal.vue";
import { handleUserPhone } from "@/api/userInfo_user";
import yzModalImg from "./yzModalImg";
import handleImgItem from "@/views/pages/admin/user/examinee/comp/handleImgItem.vue";

/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
    yzModalImg,
    YzModal,
    handleImgItem,
  },
  data() {
    return {
      title: "个人资料完善",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "照片信息",
          active: true,
        },
      ],
      page: "img",
      defaultvalue: 3,
      currentpage: 3,
      fileType: "",
      userPhoto: {},
      cut_width: 295, //裁剪宽
      cut_height: 431, //裁剪高
      imgtype: "", //图片是头像/身份证
      imgColor: "", //图片颜色类型
      direction: "", //身份证图片朝向
      defaultUrl: "@/assets/images/person/person-none.png",
      handleForm: {},
    };
  },
  methods: {
    
    getUserPhoto() {
      getStandardPhoto(this.$route.query.sfzjh).then((res) => {
        if (res.status) {
          this.userPhoto = res.data || {};
          return;
        }
      });
    },
    handle() {
      handleUserPhone(this.$route.query.sfzjh, this.handleForm).then((res) => {
        if (res.status) {
          this.$notify({
            title: "成功",
            message: "审核成功",
            type: "success",
          });
          this.$refs["yzmodal1"].hide();
          this.$refs["yzmodal2"].hide();
          this.$refs["yzmodal3"].hide();
          this.$refs["yzmodal4"].hide();
          this.$refs["yzmodal5"].hide();
          this.handleForm = {};
          this.getUserPhoto();
          this.$refs["personHeader"].init();
        }
      });
    },
    showModal(type){
      this.$refs['yzmodal'+ type].show()
    }
  },
  created() {
    this.getUserPhoto();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = userInfo ? userInfo : "";
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <PersonHeader :page="page" ref="personHeader" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-4">照片及身份证件认证</span>
              <span class="mr-2"
                ><i class="iconfont icon-ze-user-o mr-2"></i>姓名</span
              >
              <span class="mr-2">{{ userPhoto.xm }}</span>
              <span class="mr-2"
                ><i class="iconfont icon-idcard1e mr-2"></i> 身份证号</span
              >
              <span class="mr-2">{{ userPhoto.sfzjh }}</span>
            </h4>
            <div>
              <div class="d-flex p-3">
                <handleImgItem
                  title="系统标准照（白底）"
                  :imgId="userPhoto.bzz"
                  des="（必须）尺寸为宽295px，高为413px，检测是否符合要求请下载照片处理工具。"
                  :shzt="userPhoto.xbzzshzt?userPhoto.xbzzshzt:userPhoto.bzzshzt ? userPhoto.bzzshzt : userPhoto.bzzxthyzt"
                  :shsm="userPhoto.xbzzshzt?userPhoto.xbzzshsm:userPhoto.bzzshzt? userPhoto.bzzshsm : userPhoto.bzzxthysm"
                  :type="1"
                  :sfts="userPhoto.bzzxthyzt == 1 || userPhoto.bzzshzt == 1"
                  module="UserPhoto"
                  :sfpdx="true"
                  :sid="userPhoto.sid"
                  :sfxslzzp="!!userPhoto.xbzz"
                  @showModal="showModal"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  title="系统标准照（蓝底）"
                  :imgId="userPhoto.ldz"
                  des="（必须）尺寸为宽295px，高为413px，检测是否符合要求请下载照片处理工具。"
                  :shzt="
                    userPhoto.ldzshzt ? userPhoto.ldzshzt : userPhoto.ldzxthyzt
                  "
                  :shsm="
                    userPhoto.ldzshzt ? userPhoto.ldzshsm : userPhoto.ldzxthysm
                  "
                  :type="2"
                  :sfts="false"
                  module="UserPhoto"
                  :sid="userPhoto.sid"
                  :sfpdx="true"
                  @showModal="showModal"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  title="系统标准照（红底）"
                  :imgId="userPhoto.hdz"
                  des="（必须）尺寸为宽295px，高为413px，检测是否符合要求请下载照片处理工具。"
                  :shzt="
                    userPhoto.hdzshzt ? userPhoto.hdzshzt : userPhoto.hdzxthyzt
                  "
                  :shsm="
                    userPhoto.hdzshzt ? userPhoto.hdzshsm : userPhoto.hdzxthysm
                  "
                  :type="3"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserPhoto"
                  :sfpdx="true"
                  @showModal="showModal"
                  @success="getUserPhoto"
                ></handleImgItem>
              </div>
            </div>
            <div>
              <h4 class="card-title ml-3 mt-3">上传身份证扫描件</h4>
              <div class="d-flex p-3">
                <handleImgItem
                  title="身份证扫描件（人像面）"
                  :imgId="userPhoto.sfzsmjrxm"
                  :shzt="
                    userPhoto.zjshzt ? userPhoto.zjshzt : userPhoto.sfztxhyzt
                  "
                  :shsm="
                    userPhoto.zjshzt ? userPhoto.zjshbz : userPhoto.sfztxhysm
                  "
                  :type="4"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserIdPhoto"
                  :sfpdx="false"
                  @showModal="showModal"
                  @success="getUserPhoto"
                ></handleImgItem>
                <handleImgItem
                  title="身份证扫描件（国徽面）"
                  :imgId="userPhoto.sfzsmjghm"
                  :shzt="
                    userPhoto.zjshzt ? userPhoto.zjshzt : userPhoto.sfztxhyztfm
                  "
                  :shsm="
                    userPhoto.zjshzt ? userPhoto.zjshbz : userPhoto.sfztxhysmfm
                  "
                  :type="5"
                  :sfts="false"
                  :sid="userPhoto.sid"
                  module="UserIdPhoto"
                  :sfpdx="false"
                  @showModal="showModal"
                  @success="getUserPhoto"
                ></handleImgItem>
              </div>
            </div>
            <a
              :href="
                '/admin/personalEducation?sfzjh=' + this.$route.query.sfzjh
              "
              class="pl-3"
              ><button type="button" class="btn btn-info h35 sub-btn">
                审定照片信息完成，点击继续审定
              </button></a
            >
          </div>
        </div>
      </div>
    </div>

    <yzModalImg
      ref="yzmodal1"
      :img1="userPhoto.bzz"
      :img2="userPhoto.xbzz"
      title="审定系统标准照（白底）"
      :bound="['bzzshzt', 'bzzshsm']"
      v-model="handleForm"
      @change="handle"
    ></yzModalImg>
    <YzModal
      ref="yzmodal2"
      title="审定系统标准照（蓝底）"
      :bound="['ldzshzt', 'ldzshsm']"
      v-model="handleForm"
      @change="handle"
    ></YzModal>
    <YzModal
      ref="yzmodal3"
      title="审定系统标准照（红底）"
      :bound="['hdzshzt', 'hdzshsm']"
      v-model="handleForm"
      @change="handle"
    ></YzModal>
    <YzModal
      ref="yzmodal4"
      title="审定身份证扫描件（人像面|国徽面）"
      :bound="['zjshzt', 'zjshbz']"
      v-model="handleForm"
      @change="handle"
    ></YzModal>
    <YzModal
      ref="yzmodal5"
      title="审定身份证扫描件（人像面|国徽面）"
      :bound="['zjshzt', 'zjshbz']"
      v-model="handleForm"
      @change="handle"
    ></YzModal>
  </Layout>
</template>
