<template>
  <div class="per-img-box">
    <div class="per-img-top flexList">
      {{ title }}
    </div>
    <div
      class="per-img-photo flexList justify-content-center"
      :class="{ certifi: type > 3 }"
    >
      <img :src="imgId ? getFileUrl(imgId) : defaultImg" alt="加载失败" />
    </div>
    <div class="per-img-desc line2" v-if="des">
      {{ des }}
    </div>
    <div class="per-img-check p-2 text-info" v-if="imgId">
      <span v-if="shzt == 1">
        <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i>
        {{ shsm ? shsm : "审核通过" }}
      </span>
      <span v-else-if="shzt == 2">
        <i class="iconfont icon-ze-clear text-danger mr-2"></i>
        {{ shsm ? shsm : "审核未通过" }}
      </span>
      <span v-else>
        <i class="iconfont icon-icon_minus-circled text-secondary mr-2"></i>
        {{ "待审" }}
      </span>
    </div>
    <div class="per-img-check p-2" v-if="!imgId">未上传</div>
    <div class="per-img-file p-2">
      <button type="button" class="btn btn-info h35 sub-btn" @click="showModal">
        {{ shText }}
      </button>
    </div>
    <!-- <ImgUp
      @showTwoImg="$emit('showTwoImg')"
      @success="$emit('success', $event)"
      :type="type"
      :sfts="sfts"
      :sfpdx="sfpdx"
      :module="module"
      :sid="sid"
      :sfxslzzp="sfxslzzp"
    ></ImgUp> -->
  </div>
</template>
<script>
// import ImgUp from "@/components/image/imgUp";
import YzModal from "@/components/form/yzModal.vue";
import yzModalImg from "../yzModalImg";
export default {
  props: {
    title: String,
    imgId: String,
    des: String,
    shzt: Number,
    shsm: String,
    type: Number,
    sfts: Boolean,
    module: String,
    sid: String,
    sfpdx: Boolean,
    sfxslzzp: {
      default: function () {
        return false;
      },
    },
    showTwoImg: {
      default: function () {
        return null;
      },
    },
  },
  computed: {
    defaultImg() {
      if (this.type < 4) {
        return require("@/assets/images/person/person-none.png");
      }
      if (this.type == 4) {
        return require("@/assets/images/person/card1.png");
      }
      if (this.type == 5) {
        return require("@/assets/images/person/card2.png");
      }
    },
    shText() {
      return this.type == 1
        ? "审定系统标准照（白底）"
        : this.type == 2
        ? "审定系统标准照（蓝底）"
        : this.type == 3
        ? "审定系统标准照（红底）"
        : this.type == 4
        ? "审定身份证扫描件（人像面）"
        : "审定身份证扫描件（国徽面）";
    },
  },
  methods: {
    showModal() {
      this.$emit("showModal", this.type);
    },
  },
  components: {
    // ImgUp,
    yzModalImg,
    YzModal,
  },
};
</script>

<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.custom-file-label {
  padding-top: 4px;
}
/deep/ .el-upload--text {
  width: 100% !important;
}
.editImg {
  position: absolute;
  min-width: 100px;
  cursor: pointer;
  text-decoration: underline;
}
.itemImg {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.itemImg img {
  height: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.itemImg div {
  font-size: 14px;
  margin-top: 10px;
}
</style>
